import React from 'react';
import PropTypes from 'prop-types';

import FancyDropdownContext from 'dpl/components/FancyDropdown/context';
import FancyDropdownListItem from 'dpl/components/FancyDropdown/FancyDropdownListItem';

export default function FancyDropdownMenuItem({
  children,
  itemKey,
  className,
  selectedClassName
}) {
  return (
    <FancyDropdownContext.Consumer>
      {({
        selectedMenuItemKey,
        addMenuItemKey,
        removeMenuItemKey,
        setSelectedMenuItemKey,
        refreshMenuItemKeyPosition,
        menuRef,
        toggleOpen,
        shouldCloseOnItemClick
      }) => (
        <FancyDropdownListItem
          className={className}
          selectedClassName={selectedClassName}
          isSelected={selectedMenuItemKey === itemKey}
          menuRef={menuRef}
          onUpdate={() => {
            refreshMenuItemKeyPosition(itemKey);
          }}
          onMount={() => {
            addMenuItemKey(itemKey);
          }}
          onUnmount={() => {
            removeMenuItemKey(itemKey);
          }}
          onMouseEnter={() => {
            setSelectedMenuItemKey(itemKey);
          }}
          onClick={shouldCloseOnItemClick ? () => toggleOpen(false) : null}
        >
          {children}
        </FancyDropdownListItem>
      )}
    </FancyDropdownContext.Consumer>
  );
}

FancyDropdownMenuItem.propTypes = {
  itemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  selectedClassName: PropTypes.string
};
FancyDropdownMenuItem.defaultProps = {
  className: '',
  selectedClassName: 'bg-near-white'
};
